import styles from './Accessibilitat.module.sass'
import '../../styles/global.sass'
import BtnLink from "../../components/layout/buttons/link/BtnLink"


function Accessibilitat() {
    return(
        <div className={styles.container}>
            <div className='margin_top'></div>
            <div className='container'>
                <h1>Acessibilitat</h1>
                
                <div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            Recomanacions d'accés
                        </div>
                        <div className={styles.body_regular}>
                            Navegador recomanat: Google Chrome, Microsoft Edge, Mozilla Firefox o similar en les seves versions actuals.
                            <br></br>
                            Resolució recomanada: Suporta 1280x768 o superior.          
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            Continguts no html
                        </div>
                        <div className={styles.body_regular}>
                            Format de document portàtil (PDF).          
                        </div>
                        <div className={styles.link}>
                            <BtnLink
                                onClick={()=> {window.open('http://www.adobe.es/products/acrobat/readstep2.html', '_blank')}}
                                text={'Descarregar el programa Adobe Acrobat Reader'}
                            />          
                        </div>
                        <div className={styles.link}>
                            <BtnLink
                            onClick={()=> {window.open('http://www.foxitsoftware.com/pdf/reader_2/down_reader.htm', '_blank')}}
                            text={'Descarregar el programa Foxit Reader'}
                            />          
                        </div>
                        <div className={styles.body_regular}>
                            Format de document de text (ODT).          
                        </div>
                        <div className={styles.link}>
                            <BtnLink
                            onClick={()=> {window.open('http://ca.openoffice.org/', '_blank')}}
                            text={'Descarregar el paquet ofimàtic Star Office'}
                            />          
                        </div>

                        <div className={styles.section}>
                            <div className={styles.p2}>
                                Webs amb Accesit i activació del mode accessible
                            </div>
                            <div className={styles.body_regular}>
                                Quan es navega en una web que disposa de servei Accesit, apareix una pestanya, com la indicada a continuació, a la part esquerra de la web. Pulsa sobe ella i activaràs el servei d'Accesit.
                                <p></p>
                                Per saber més sobre Accesit, fes <a href="https://bopadocuments.blob.core.windows.net/bopa-documents/manual/Manual_us_Acctua.pdf" target='_blank'>clic aquí</a> .
                                
                            </div>
                        </div>
                        
                        <div className={styles.section}>
                            <div className={styles.p2}>
                                Pàgina web accessible
                            </div>
                        <div className={styles.body_regular}>
                        El Govern d'Andorra pretén facilitar l'accés universal, independentment de les limitacions tècniques, físiques, sensorials o intel·lectuals de les persones que la consulten i del context d'ús en què ho fan (tipus de dispositiu, programari, velocitat de la connexió, condicions ambientals, etc.), a les seves pàgines web.
                            <p></p>
                            Per això, es treballa amb l'objectiu d'adaptar tot el web a les recomanacions del <a href="https://www.w3.org/">Consorci World Wide Web - W3C</a> per tal d'eliminar les barreres que dificulten l'accés a la informació i la comunicació. Aquestes recomanacions es concreten en les pautes d'accessibilitat al contingut de la web WCAG 1.0.
                            <p></p>
                            El compromís del Govern d'Andorra és arribar a satisfer, com a mínim, els punts de verificació de prioritat 1 i 2 de les pautes WCAG, i així complir el nivell de conformitat "Doble A" (AA). Sempre que sigui possible, també s'intentarà complir els punts de verificació de prioritat 3.
                            
                        </div>
                    </div>
                        
                    </div> 
                    
                </div>
            </div>
        </div>                        
        








        
    )
}

export default Accessibilitat