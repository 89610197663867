
import styles from './ExpiredDocument.module.sass'
import '../../styles/global.sass'
import Caducado from '../../assets/ilustrations/Caducado.png'
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary"
import { BsArrowRight } from "react-icons/bs"
import { useNavigate } from 'react-router-dom'

function ExpiredDocument() {
    let navigate = useNavigate();
    return (
        <div className='container'>
            <div className={styles.title }>El document ha caducat</div>
            <div className={styles.subTitle }>Malauradament, el document que busca ja no està disponible.</div>
            <div className={styles.button }>
                <BtnPrimary 
                    text={"Tornar "} 
                    iconRight={<BsArrowRight/>} 
                    onClick={() => navigate(-1)}
            // onClick={()=>{window.open('/' + (window.location.pathname.split('/')[1] != 'bopa' ? window.location.pathname.split('/')[1] : '' ), '_self')}}
                />
            </div>
            <div className={styles.image}><img src={Caducado} /></div>
            
        </div>      
    )
}

export default ExpiredDocument