import styles from './PreguntesFrequents.module.sass'
import '../../styles/global.sass'


function PreguntesFrequents() {
    return(
        <div className={styles.container}>
            <div className='margin_top'></div>
            <div className='container'>
                <h1>Preguntes freqüents</h1>
                
                <div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            1. Què és el BOPA?
                        </div>
                        <div className={styles.body_regular}>
                            El <i>Butlletí Oficial del Principat d'Andorra</i> (BOPA) és el mitja de publicació oficial de les normes jurídiques i dels actes i anuncis que han de ser objecte de publicitat oficial.
                        </div>
                    </div> 
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            2. Quins tipus de documents es poden publicar al BOPA?
                        </div>
                        <div className={styles.body_regular}>
                            2.1. Es publiquen al <i>Butlletí Oficial del Principat d'Andorra</i> les lleis, els tractats i els acords internacionals i les altres normes jurídiques, així com els acords, els actes i els anuncis dels coprínceps, del Consell General, del Govern, del Tribunal Constitucional, del Consell Superior de la Justícia, de l'Administració de Justícia, dels comuns, dels quarts, dels veïnats, i dels altres organismes públics que, d'acord amb una norma jurídica, hagin de ser objecte de publicitat. 
                            <br></br>
                            2.2. També és publiquen al BOPA anuncis no oficials en els supòsits en què ho determina una norma jurídica. Aquestes insercions són de pagament, el preu de les quals està establert en l'article 23 de la llei 25/2014, del 30 d'octubre, del Butlletí Oficial del Principat d'Andorra.
                        </div>
                    </div> 
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            3. Quan es publica el BOPA?
                        </div>
                        <div className={styles.body_regular}>
                            El <i>Butlletí Oficial del Principat d'Andorra</i> es publica tots els dimecres de l'any, en cas de ser festiu, es publicarà l'endemà. 
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            4. Com consultar el BOPA?
                        </div>
                        <div className={styles.body_regular}>
                            El BOPA es pot consultar per la cerca general o bé per temes i/o organismes. 
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            5. Com donar-me d'alta al servei d'alertes i perquè?
                        </div>
                        <div className={styles.body_regular}>
                            Aquest servei és gratuït i us permet rebre la informació a la quals us hàgiu subscrit. A més, resulta molt còmode, ja que les notificacions inclouen l'enllaç corresponent per tal que pugueu visualitzar el seu contingut. Anar a “Accés usuari” i registrar-se. - Les meves alertes: És un servei gratuït que us ofereix la possibilitat de rebre per correu electrònic aquells anuncis que siguin del vostre interès, el mateix dia que es publiquen. - Les meves cerques: Possibilitat de guardar criteris de cerca perquè en un sol clic puguis tenir la informació que t'interessa i també rebre una alerta. En cap cas el <i>Butlletí Oficial del Principat d'Andorra</i> es fa responsable de la no recepció del sistema d'alertes. - Els meus articles favorits: Marcar com a favorits els documents que més consultem.
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            6. Què és el format ePUB?
                        </div>
                        <div className={styles.body_regular}>
                            EPUB o ePUB (acrònim de la expressió anglesa Electronic publication - Publicació electrònica) és un format de document estàndar orientat a la seva visualització en dispositius tipus llibre electrònic (e-book). El format ePUB té com a principal avantatge la independència del contingut i la forma, el que suposa que es pot adaptar a qualsevol dispositiu de lectura de forma ràpida i automàtica. S'adapta a les diferents mides de pantalla i fonts de la majoria de lectors de llibres electrònics. A més de poder ser utilitzats amb lectors electrònics tipus e-book, els documents ePUB del BOPA poden ser visualitzats amb altres tipus de dispositius mòbils com telèfons (Android, iPhone, Windows Mobile, Symbian...), tablet (iPad), etc.
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            7. És oficial el BOPA per Internet?
                        </div>
                        <div className={styles.body_regular}>
                            En compliment de la disposició transitòria primera de la Llei 25/2014, del 30 d'octubre, del <i>Butlletí Oficial del Principat d'Andorra</i>, el Govern acorda el dia 17 de desembre del 2014, que la publicació del Butlletí Oficial del Principat d'Andorra es publicarà en paper fins al 31 de desembre del 2014, a partir d'aquesta data es publica, amb caràcter oficial, a la seu electrònica www.bopa.ad.
                        </div>
                    </div>  
                </div>
            </div>
        </div>                  
    )
}

export default PreguntesFrequents